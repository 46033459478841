import view from './view.json';

const replace = {
  // C
  changeSMID: view.changeASKOMAID,

  // R
  reqSMID: view.reqASKOMAID,

  // S
  SMID: view.ASKOMAID,
  SMIDTolltip: view.ASKOMATolltip
};

export default replace;
