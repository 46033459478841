/* eslint-disable react/jsx-props-no-spreading */
import React, { useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import { Collapse } from '@mui/material';

import { BillingAddress } from '../../../components';
import { Checkbox, LanguageSelector, CustomTextInput, CountrySelector, CustomSelect, UserPhoneInput } from '../../../components/ReduxFormFields';
import { CancelButton } from '../../../components/UIKit';
import { AvatarPreviewer } from '../components';
import DataList from '../../DataList';
import { required, minLength, maxLength, checkValidPhone } from '../../validation';
import { statusList, defaultOEM, dataListPviId, getPvInstallersConfig, SETTING_PROFILE_FORM } from '../constants';
import { getOEMs, getOEMsByID } from '../slice';
import {
  getSettingProfileInitialDataConnectedOemSelector,
  getSettingProfileInitialDataCompanySelector,
  getSettingProfileOemsListSelector,
  isInvitedUserHasReadOnlyPermissionSettingSelector,
  isPvEmployeeInstallHasReadOnlyAccessSettingSelector
} from '../../../redux-store/selectors/settingProfile';
import { getDataListCompaniesDataSelector } from '../../../redux-store/selectors/dataList';
import { OEM_CONNECTED_OEMS_URL } from '../../../api/apiUrls';
import { APP_ID, ASKOMA_APP_ID, CKW_APP_ID, EZEE_APP_ID, HELION_APP_ID, RENNERGY_APP_ID, SOLAR_MANAGER_APP_ID, THERMONDO_APP_ID } from '../../../config';
import { useZipCodeValidation } from '../../../hooks';
import { READ_ONLY_ROLES } from '../../../constants';

import i18n from '../../../i18n';
import '../../../App.css';

const minLen3 = minLength(3);
const maxLen50 = maxLength(50);
const maxLen100 = maxLength(100);

const transformResponse = (response) => {
  const data = response.list
    .filter(({ my_company: { _id, name, admin } = {} }) => _id && name && admin)
    .map(({ my_company: { _id, name, admin: adminId } }) => ({ id: _id, _id, name, adminId }));
  return { data };
};

const valueSelector = formValueSelector(SETTING_PROFILE_FORM);

/**
 * Edit profile of user with roleType = 'end_user' - FormUser
 * @memberof module:SettingProfile
 */
const FormUser = (props) => {
  const {
    openChangePassword,
    sendEmailRequest,
    openChangeEmail,
    openChangeSMID,
    initialValues,
    handleSubmit,
    userRoleName,
    initialize,
    myRoleType,
    submitting,
    pristine,
    myself,
    goBack,
    change,
    userApp
  } = props;
  const dispatch = useDispatch();
  const { userName } = initialValues || {};

  const first_name = useSelector((state) => valueSelector(state, 'first_name'));
  const last_name = useSelector((state) => valueSelector(state, 'last_name'));
  const company = useSelector((state) => valueSelector(state, 'company'));
  const isPropMgmtEndUser = useSelector((state) => valueSelector(state, 'isPropMgmtEndUser'));
  const dataListCompanies = useSelector(getDataListCompaniesDataSelector);
  const dataListOems = useSelector(getSettingProfileOemsListSelector);
  const pvInstaller = useSelector(getSettingProfileInitialDataCompanySelector);
  const oem = useSelector(getSettingProfileInitialDataConnectedOemSelector);
  const isBillingAddressEnabled = useSelector((state) => valueSelector(state, 'isBillingAddressEnabled'));
  const zipValidationMain = useZipCodeValidation(valueSelector);
  const zipValidationBilling = useZipCodeValidation(valueSelector, 'billingAddress.country');

  const getStatusList = statusList();

  const optionalRequiredForThermondo = useCallback((value) => {
    if (APP_ID === THERMONDO_APP_ID && !myself) {
      return undefined;
    }
    return required(value);
  }, [myself]);

  const getAdminId = (companies, pviId) => {
    const chosenCompany = companies.find((item) => item?._id && item._id === pviId) || {};
    return chosenCompany?.adminId;
  };

  const companiesListData = useMemo(
    () => dataListCompanies
      .filter((item) => item?._id !== pvInstaller?._id)
      .map((item) => ({ value: item._id, label: item.name }))
      .concat(pvInstaller ? { value: pvInstaller?._id, label: pvInstaller?.name } : []),
    [dataListCompanies, pvInstaller]
  );

  const oemsListData = useMemo(
    () => (company && company !== 'null'
      ? dataListOems
        .filter((item) => item?.oem?._id !== oem?._id)
        .map((item) => ({ value: item.oem._id, label: `${item.oem.name}` }))
        .concat((oem && pvInstaller && pvInstaller?._id === company) ? { value: oem?._id, label: oem?.name } : [])
      : []),
    [company, dataListOems, oem, pvInstaller]
  );

  const isReadOnlyPermission = useSelector(isInvitedUserHasReadOnlyPermissionSettingSelector);
  const isPvEmployeeInstallHasReadOnlyAccess = useSelector(isPvEmployeeInstallHasReadOnlyAccessSettingSelector);
  const isReadOnlyRole = READ_ONLY_ROLES.includes(myRoleType);
  const hasServiceRights = ['root', 'solar_admin', 'property_management', 'pv_installer', 'pv_installer_employee', 'pv_installer_employee_read_only'].includes(myRoleType);
  const hasSupportRights = ['root', 'solar_admin', 'property_management'].includes(myRoleType) || (['pv_installer'].includes(myRoleType) && initialValues.status === 'active');
  const hasRightsToChangeInstaller = ['root', 'solar_admin', 'oem'].includes(myRoleType) && !isReadOnlyPermission;
  const roleDependencies = { disabled: !myself && (isReadOnlyRole || isReadOnlyPermission || isPvEmployeeInstallHasReadOnlyAccess) };
  const serviceRoleDependencies = { disabled: company && company !== 'null' && oemsListData.length };
  const displayConnectedOem = hasServiceRights && !!oemsListData.length && !isReadOnlyRole;
  const additionalOptionForCompanyField = myRoleType === 'oem' ? [] : [{ value: 'null', label: i18n.t('noComp') }];
  const { url, params } = getPvInstallersConfig[myRoleType] || getPvInstallersConfig.default;
  const isThermondoUser = userApp === 'thermondoEndUserApp';

  useEffect(() => {
    if (['pv_installer_employee', 'pv_installer'].includes(myRoleType)) {
      dispatch(getOEMs());
    }

    initialize(initialValues);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!company) {
      change('connectedOem', defaultOEM.value);
    }

    if (['root', 'solar_admin'].includes(myRoleType) && company && company !== 'null') {
      const adminId = getAdminId(dataListCompanies, company);

      const request = {
        default: {
          urlLink: `${OEM_CONNECTED_OEMS_URL}/${adminId}`,
          paramsData: {}
        }
      };

      if (adminId) {
        const { urlLink, paramsData } = request?.[myRoleType] || request.default;
        dispatch(getOEMsByID({ url: urlLink, params: paramsData }));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [company, dataListCompanies]);

  return (
    <form onSubmit={handleSubmit}>
      {!['end_user', 'viewer', 'pv_installer_employee_read_only', 'pv_installer', 'pv_installer_employee', 'property_management', 'oem_employee_read_only'].includes(myRoleType) && (
        <DataList
          listID={dataListPviId}
          listURL={url}
          params={params}
          transformResponse={transformResponse}
          forceKeepData
        />
      )}
      <div className="nav-settings">
        <div className="container-fluid">
          <div className="row align-items-center">
            <div className="col-auto mr-sm-auto">
              <h6 className="set-prof-head">
                {i18n.t('settingsProfile')}
                {!myself && (
                  <>
                    :&nbsp;
                    {i18n.t(userRoleName)}
                  </>
                )}
              </h6>
            </div>
            {!roleDependencies.disabled && (
              <div className="col-auto">
                <button
                  disabled={pristine || submitting}
                  onClick={handleSubmit}
                  className="btn m-btn--pill m-btn--air btn-secondary btn-table-inst btn-save-chan"
                  type="button"
                >
                  {i18n.t('saveChanges')}
                </button>
              </div>
            )}
            <div className="col-auto cont-for-canc-btn">
              <CancelButton onClickHandler={goBack} customButtonClass="emp-set-btn-canc" />
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="row align-items-md-start justify-content-md-start justify-content-center settings-user-details">
          <div className="col-md-3 col-auto user-photo">
            <Field
              name="avatar"
              type="file"
              userName={`${first_name} ${last_name}`}
              avatar={initialValues?.avatarURL}
              component={AvatarPreviewer}
              {...roleDependencies}
            />
          </div>
          <div className="col-md-8">
            <div className="row justify-content-between">
              <div className="col-lg-5">
                <div className="form-group m-form__group input-field">
                  <Field
                    name="company_name"
                    component={CustomTextInput}
                    label={i18n.t('userCompany')}
                    className="m-input"
                    autoComplete="off"
                    validate={[maxLen100]}
                    {...roleDependencies}
                  />
                </div>
                <div className="form-group m-form__group input-field">
                  <Field
                    name="first_name"
                    component={CustomTextInput}
                    label="firstName"
                    required={THERMONDO_APP_ID !== APP_ID || myself}
                    className="m-input"
                    autoComplete="off"
                    validate={[optionalRequiredForThermondo, minLen3, maxLen50]}
                    {...roleDependencies}
                  />
                </div>
                <div className="form-group m-form__group input-field">
                  <Field
                    name="last_name"
                    component={CustomTextInput}
                    label="lastName"
                    required={THERMONDO_APP_ID !== APP_ID || myself}
                    className="m-input"
                    autoComplete="off"
                    validate={[optionalRequiredForThermondo, minLen3, maxLen50]}
                    {...roleDependencies}
                  />
                </div>
                {!myself && (
                  <div className="form-group m-form__group input-field">
                    <Field
                      name="plant"
                      component={CustomTextInput}
                      label={i18n.t('plantLabel')}
                      showTooltip={i18n.t('plantTooltip')}
                      className="m-input"
                      autoComplete="off"
                      {...roleDependencies}
                    />
                  </div>
                )}
                <div className="form-group m-form__group input-field">
                  <Field
                    name="old_sm_id"
                    component={CustomTextInput}
                    label={i18n.t('reqSMID')}
                    className="m-input"
                    validate={[required, minLen3, maxLen50]}
                    {...roleDependencies}
                    disabled
                  />
                  {hasServiceRights && !isReadOnlyRole && !isReadOnlyPermission && !isPvEmployeeInstallHasReadOnlyAccess && (
                    <div style={{ position: 'relative' }}>
                      <button
                        type="button"
                        onClick={openChangeSMID}
                        className="change-btn"
                        style={{ top: '2px' }}
                        disabled={isReadOnlyRole}
                      >
                        {i18n.t('Gateway Replacement')}
                      </button>
                    </div>
                  )}
                </div>
              </div>
              <div className="col-lg-5">
                {hasServiceRights && (
                  <div className="form-group m-form__group input-field">
                    <div className="flags-select-label">
                      {i18n.t('status')}
                    </div>
                    <Field
                      name="status"
                      component={CustomSelect}
                      placeholder={i18n.t('selectStatus')}
                      options={getStatusList}
                      isSearchable={false}
                      validate={[required]}
                      filterOption={({ value }) => value === 'active' || value === 'deactivated'}
                      {...roleDependencies}
                      disabled={!hasSupportRights || isReadOnlyPermission || isPvEmployeeInstallHasReadOnlyAccess}
                    />
                  </div>
                )}
                <div className="form-group m-form__group input-field input-field-for-ch-pass">
                  {userName
                    ? (
                      <Field
                        name="userName"
                        component={CustomTextInput}
                        label={i18n.t('username')}
                        className="m-input"
                        disabled={!hasSupportRights || isReadOnlyPermission || isPvEmployeeInstallHasReadOnlyAccess}
                        required
                      />
                    ) : (
                      <Field
                        name="old_email"
                        disabled
                        component={CustomTextInput}
                        label={i18n.t('mail')}
                        className="m-input"
                      />
                    )}
                  {(!roleDependencies.disabled && myRoleType !== 'viewer') && (
                    <>
                      {!userName && (
                        <button
                          type="button"
                          onClick={sendEmailRequest}
                          className="resend-btn"
                        >
                          {i18n.t('resendEmail')}
                        </button>
                      )}
                      <button
                        type="button"
                        onClick={openChangeEmail}
                        className="change-btn"
                      >
                        {i18n.t('changeMail')}
                      </button>
                    </>
                  )}
                </div>
                {myself && (
                  <div className="form-group m-form__group input-field input-field-for-ch-pass">
                    <Field
                      name="password"
                      type="password"
                      disabled
                      component={CustomTextInput}
                      label="pass"
                      className="m-input"
                      input={{ value: 'password' }}
                    />
                    <button
                      type="button"
                      onClick={openChangePassword}
                      className="change-btn"
                    >
                      {i18n.t('changePass')}
                    </button>
                  </div>
                )}
                <div className="form-group m-form__group input-field input-filed-flags-select">
                  <div className="flags-select-label">
                    {i18n.t('Country')}
                  </div>
                  <Field
                    name="country"
                    id="country"
                    component={CountrySelector}
                    buttonClassName="country-btn"
                    label={i18n.t('Country')}
                    className="m-input flagSelectClosed"
                    {...roleDependencies}
                    validate={[required]}
                  />
                </div>
                <div className="form-group m-form__group input-field">
                  <Field
                    name="phone"
                    component={UserPhoneInput}
                    label={i18n.t('phoneNumber')}
                    className="m-input"
                    autoComplete="off"
                    country={initialValues.country}
                    validate={!isThermondoUser ? [checkValidPhone, required] : [checkValidPhone]}
                    isRequired={!isThermondoUser}
                    {...roleDependencies}
                  />
                </div>
              </div>
            </div>
            <hr />
          </div>
        </div>
        <div className="row align-items-md-start justify-content-md-start justify-content-center">
          <div className="col-md-3 col-auto user-photo" />
          <div className="col-md-8">
            <div className="row justify-content-between align-items-start">
              <div className="col-lg-5">
                <div className="form-group m-form__group input-field input-filed-flags-select">
                  <div className="flags-select-label">
                    {i18n.t((THERMONDO_APP_ID === APP_ID && !myself) ? 'language' : 'reqLanguage')}
                  </div>
                  <Field
                    name="language"
                    component={LanguageSelector}
                    buttonClassName="reqLanguage-btn"
                    id="reqLanguage"
                    label="language"
                    required={THERMONDO_APP_ID === APP_ID || myself}
                    className="m-input flagSelectClosed"
                    isThermondoUser={isThermondoUser}
                    {...roleDependencies}
                  />
                </div>
                {(!['pv_installer_employee_read_only', 'pv_installer', 'pv_installer_employee', 'oem_employee_read_only'].includes(myRoleType)) && (
                  <div className="form-group m-form__group input-field">
                    <div className="flags-select-label">
                      {i18n.t('reqPVI')}
                    </div>
                    <Field
                      name="company"
                      component={CustomSelect}
                      placeholder={i18n.t('selectPVI')}
                      options={[...additionalOptionForCompanyField, ...companiesListData]}
                      // "null" used as default value for a "No PV Installer" label
                      defaultValue="null"
                      validate={[required]}
                      {...roleDependencies}
                      disabled={!hasRightsToChangeInstaller}
                      searchable
                    />
                  </div>
                )}
                {displayConnectedOem && (
                  <div className="form-group m-form__group input-field">
                    <div className="flags-select-label">
                      {i18n.t('oem')}
                    </div>
                    <Field
                      name="connectedOem"
                      component={CustomSelect}
                      placeholder={i18n.t('selectOEM')}
                      options={[...oemsListData, defaultOEM]}
                      defaultValue={defaultOEM.value}
                      {...serviceRoleDependencies}
                      disabled={!displayConnectedOem || isReadOnlyPermission || isPvEmployeeInstallHasReadOnlyAccess || ['property_management'].includes(myRoleType)}
                      searchable
                    />
                  </div>
                )}
              </div>
              <div className="col-lg-5">
                <div className="form-group m-form__group input-field input-field-street">
                  <Field
                    name="street"
                    component={CustomTextInput}
                    label={i18n.t('street')}
                    className="m-input"
                    autoComplete="off"
                    validate={[minLen3, maxLen50, optionalRequiredForThermondo]}
                    required={THERMONDO_APP_ID !== APP_ID || myself}
                    {...roleDependencies}
                  />
                </div>
                <div className="row city-zip">
                  <div className="form-group m-form__group input-field col-7 city-inp">
                    <Field
                      name="city"
                      component={CustomTextInput}
                      label={i18n.t('city')}
                      className="m-input"
                      autoComplete="off"
                      validate={[minLen3, maxLen50, optionalRequiredForThermondo]}
                      required={THERMONDO_APP_ID !== APP_ID || myself}
                      {...roleDependencies}
                    />
                  </div>
                  <div className="form-group m-form__group input-field offset-1 col-4 zip-inp">
                    <Field
                      name="zip"
                      component={CustomTextInput}
                      label={i18n.t('zip')}
                      className="m-input"
                      autoComplete="off"
                      validate={[zipValidationMain, optionalRequiredForThermondo]}
                      required={THERMONDO_APP_ID !== APP_ID || myself}
                      {...roleDependencies}
                    />
                  </div>
                </div>
                {!myself && (
                  <div className="form-group m-form__group input-field note-input-field">
                    <Field
                      name="note"
                      component={CustomTextInput}
                      label={i18n.t('userSettingNotesLabel')}
                      showTooltip={i18n.t('userSettingNotesTooltip')}
                      className="m-input pr-4"
                      autoComplete="off"
                      multiline
                      maxRows={6}
                      aria-label="note"
                      {...roleDependencies}
                    />
                  </div>
                )}
                {['root', 'solar_admin'].includes(myRoleType) && (
                  <div className="form-group m-form__group input-field note-input-field">
                    <Field
                      name="adminNote"
                      component={CustomTextInput}
                      label={i18n.t('adminNotes')}
                      className="m-input"
                      autoComplete="off"
                      multiline
                      maxRows={6}
                      aria-label="adminNote"
                      {...roleDependencies}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        {[HELION_APP_ID, SOLAR_MANAGER_APP_ID, CKW_APP_ID, EZEE_APP_ID, THERMONDO_APP_ID, RENNERGY_APP_ID, ASKOMA_APP_ID].includes(APP_ID) && (
          <div className="row align-items-md-start justify-content-md-start justify-content-center">
            <div className="col-md-3 col-auto user-photo" />
            <div className="col-md-8">
              <hr />
              <div className="every-new-error py-3">
                <Field
                  type="checkbox"
                  name="isBillingAddressEnabled"
                  component={Checkbox}
                  defaultValue={initialValues.isBillingAddressEnabled || false}
                  labelClass="every-new-error-label billing-label"
                  label={i18n.t('isBillingEnabledCheckboxTitle')}
                  {...roleDependencies}
                  disabled={(!myself && isReadOnlyRole) || myRoleType === 'property_management' || isPropMgmtEndUser}
                />
              </div>
              <Collapse in={isBillingAddressEnabled}>
                {isBillingAddressEnabled && (
                  <BillingAddress
                    country={initialValues.country}
                    isBillingAddressEnabled={isBillingAddressEnabled}
                    roleDependencies={{ disabled: roleDependencies.disabled || isPropMgmtEndUser }}
                    billingReferenceRoleDependencies={roleDependencies}
                    zipValidation={zipValidationBilling}
                  />
                )}
              </Collapse>
            </div>
          </div>
        )}
      </div>
    </form>
  );
};

FormUser.propTypes = {
  initialize: PropTypes.func.isRequired,
  initialValues: PropTypes.instanceOf(Object).isRequired,
  handleSubmit: PropTypes.func.isRequired,
  goBack: PropTypes.func,
  openChangeEmail: PropTypes.func,
  openChangePassword: PropTypes.func,
  first_name: PropTypes.string,
  last_name: PropTypes.string,
  myRoleType: PropTypes.string,
  userRoleName: PropTypes.string,
  myself: PropTypes.bool,
  company: PropTypes.string,
  dataListCompanies: PropTypes.instanceOf(Array),
  dataListOems: PropTypes.instanceOf(Array),
  pristine: PropTypes.bool.isRequired,
  sendEmailRequest: PropTypes.func,
  submitting: PropTypes.bool.isRequired,
  openChangeSMID: PropTypes.func,
  getOEMs: PropTypes.func,
  status: PropTypes.string,
  userApp: PropTypes.string.isRequired,
  dispatch: PropTypes.func,
  change: PropTypes.func.isRequired,
  pvInstaller: PropTypes.instanceOf(Object),
  oem: PropTypes.instanceOf(Object)
};

const form = reduxForm({
  form: SETTING_PROFILE_FORM
})(FormUser);

export default form;
